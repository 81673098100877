import {createRouter, createWebHashHistory} from 'vue-router'
import {homeRouter} from "@/modules/home/router";
import {menuRouter} from "@/modules/menu/router";
import {contactRouter} from "@/modules/contact/router";
import {dairyRouter} from "@/modules/dairy/router";
import {bookRouter} from "@/modules/book/router";
import {cartRouter} from "@/modules/cart/router";

const routes = [
    {
        path: '/',
        alias: ['/home'],
        ...homeRouter
    },
    {
        path: '/menu',
        ...menuRouter
    },
    {
        path: '/contact',
        ...contactRouter
    },
    {
        path: '/dairy',
        ...dairyRouter
    },
    {
        path: '/book',
        ...bookRouter
    },
    {
        path: '/cart',
        ...cartRouter
    },
]

const router = createRouter({
    history: createWebHashHistory(),
    routes,
    scrollBehavior() {
        // always scroll to top
        return { top: 0 }
    },
})

export default router
