export const setLog = (state, log) => {
    state.log = log
}

export const setItems = (state, items) => {
    state.items = items
}

export const setRestaurant = (state, restaurant) => {
    state.restaurant = restaurant
}

export const setMorning = (state, morning) => {
    state.morning = morning
}

export const setAfternoon = (state, afternoon) => {
    state.afternoon = afternoon
}

export const setCategories = (state, categories) => {
    state.categories = categories
}
