import {api} from "@/main";
import axios from "axios";


export const sendOrder = async ({cart, telephone, city, direction, direction2, isCash, schedule, note, isCollect, gift, total}) => {
    const url = api + '/m'
    const params = {
        'cart': cart,
        'telephone': telephone,
        'city': city,
        'direction': direction,
        'direction2': direction2,
        'isCash': isCash,
        // 'cash': cash,
        'schedule': schedule,
        'note': note,
        'isCollect': isCollect,
        'gift': gift,
        'total': total
    }
    const config = {headers: {'content-type': 'application/json'}}
    try {
        const res = await axios.post(url, params, config)
        return {ok: true, data: res.data}
    } catch (e) {
        return {ok: false, data: e.response.data}
    }
}
