import {api} from "@/main";
import axios from "axios";

export const postLog = async ({commit}) => {
    const url = api + '/log' + '/0'
    const res = await axios.post(url)
    commit('setLog', res.data.log)
    return res.data.log
}

export const loadItems = async ({state, commit}) => {
    const url = api + '/items' // Table' + '/' + table_id
    state.isLoading = true

    try {
        const res = await axios.get(url)
        const items = res.data.items
        items.sort(function (a, b) {
            const aSize = a.price;
            const bSize = b.price;
            const aLow = a.name;
            const bLow = b.name;
            if (aSize === bSize) {
                return (aLow < bLow) ? -1 : (aLow > bLow) ? 1 : 0;
            } else {
                return (aSize < bSize) ? -1 : 1;
            }
        });
        commit('setItems', res.data.items)
        //state.isLoading = false
    } catch (e) {
        commit('setItems', [])
    }
}


export const loadLog = async ({commit}) => {
    const url = api + '/log'

    try {
        const res = await axios.get(url)
        const log = res.data.log
        commit('setLog', log)
    } catch (e) {
        commit('setLog', null)
    }
}


export const loadCategories = async ({commit}) => {
    const url = api + '/categories'
    //state.isLoading = true

    try {
        const res = await axios.get(url)
        commit('setCategories', res.data.categories)
        //state.isLoading = false
    } catch (e) {
        commit('setCategories', [])
    }
}

export const loadRestaurant = async ({commit}) => {
    const url = api + '/restaurant' + '/0'

    try {
        const res = await axios.get(url)
        const restaurant = res.data.restaurant
        commit('setRestaurant', restaurant)
        commit('setMorning', restaurant.morning.map(e => e.value))
        commit('setAfternoon', restaurant.afternoon.map(e => e.value))
        return restaurant
    } catch (e) {
        commit('setRestaurant', null)
    }
}

export const loadItem = async ({commit}, item_id) => {
    const url = api + '/item/' + item_id
    try {
        const res = await axios.get(url)
        const item = res.data.item
        commit('setItem', item)
        return item

    } catch (e) {
        commit('setItem', {})
    }
}
