<template>

  <div class="position-relative" style="min-width: 375px!important; padding:0 !important;">
    <Navbar/>

    <router-view v-slot="{Component}">
      <keep-alive>
        <component :is="Component"/>
      </keep-alive>
    </router-view>


  </div>

</template>

<script>

import {defineAsyncComponent} from "vue";

export default {
  components: {
    // eslint-disable-next-line vue/no-unused-components
    Navbar: defineAsyncComponent(() => import("@/modules/app/components/Navbar")),
  }
}
</script>


<style>
#app {
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  text-align: center;
  font-family: "Raginy", sans-serif;
}

@font-face {
  font-family: "Raginy";
  src: url('../src/assets/fonts/Raginy/Raginy-Regular.ttf') format('truetype');
  font-weight: 500;
  font-style: normal;
}

@font-face {
  font-family: "Smooch";
  src: url('../src/assets/fonts/Smooch/Smooch.ttf') format('truetype');
  font-weight: 500;
  font-style: normal;
}

@font-face {
  font-family: "Roboto";
  src: url('../src/assets/fonts/Roboto_Slab/RobotoSlab-VariableFont_wght.ttf') format('truetype');
  font-weight: 500;
  font-style: normal;
}

@font-face {
  font-family: "Argon";
  src: url('../src/assets/fonts/Argon/Argon.otf') format('opentype');
  font-weight: 500;
  font-style: normal;
}

@font-face {
  font-family: "uber-move-text";
  src: url('../src/assets/fonts/uber-move-text/UberMoveTextLight.otf') format('opentype');
  font-weight: 300;
  font-style: normal;
}

@font-face {
  font-family: "uber-move-text";
  src: url('../src/assets/fonts/uber-move-text/UberMoveTextRegular.otf') format('opentype');
  font-weight: 400;
  font-style: normal;
}

@font-face {
  font-family: "uber-move-text";
  src: url('../src/assets/fonts/uber-move-text/UberMoveTextMedium.otf') format('opentype');
  font-weight: 500;
  font-style: normal;
}

@font-face {
  font-family: "uber-move-text";
  src: url('../src/assets/fonts/uber-move-text/UberMoveTextBold.otf') format('opentype');
  font-weight: 700;
  font-style: normal;
}

@font-face {
  font-family: "merienda";
  src: url('../src/assets/fonts/Merienda/Merienda-VariableFont_wght.ttf') format('truetype');

}


@media (min-width: 576px) {
  .sm-display-none {
    display: none;
  }

  .br-3 {
    bottom: 3rem;
    right: 3rem
  }
}
</style>
