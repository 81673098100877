import {api} from "@/main";
import axios from "axios";

export const sendBook = async ({commit}, {name, telephone, email, dateInput, numPerson, note}) => {
    const url = api + '/m'
    const params = {
        'name': name,
        'telephone': telephone,
        'email': email,
        'dateInput': dateInput,
        'numPerson': numPerson,
        'note': note
    }
    const config = {headers: {'content-type': 'application/json'}}
    try {
        const res = await axios.post(url, params, config)
        return {ok: true, data: res.data}
    } catch (e) {
        return {ok: false, data: e.response.data}
    }
}
