export const menuRouter = {
    name: 'menu',
    component: () => import(/* webpackChunkName: "itemMain" */ '@/modules/menu/pages/MainPage'),
    children: [
        {
            path: '',
            name: 'menu-detail',
            component: () => import(/* webpackChunkName: "item" */ '@/modules/menu/pages/Menu'),
            props: (route) => {
                const table_id = Number(route.query.table_id)
                return isNaN(table_id) ? {table_id: 0} : {table_id: table_id}
            },
        },
        {
            path: ':id',
            name: 'item-detail',
            component: () => import(/* webpackChunkName: "itemPage" */'@/modules/menu/pages/ItemDetailPage'),
            props: (route) => {
                const id = Number(route.params.id)
                return isNaN(id) ? {it_id: 0} : {it_id: id}
            },
        }
    ],
}
