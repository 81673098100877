import {createStore} from 'vuex'
import createPersistedState from "vuex-persistedstate";

import homeModule from "@/modules/home/store";
import menuModule from "@/modules/menu/store";
import cartModule from "@/modules/cart/store";
import bookModule from "@/modules/book/store";


const store = createStore({
    modules: {
        homeModule,
        menuModule,
        cartModule,
        bookModule
    },

    plugins: [createPersistedState()]

})

export default store
