export default () => ({
    log: {},

    restaurant: {
        "restaurant_id": 0,
        "morning": [
            {
                "morning_id": 0,
                "value": true
            },
            {
                "morning_id": 1,
                "value": true
            },
            {
                "morning_id": 2,
                "value": true
            },
            {
                "morning_id": 3,
                "value": true
            },
            {
                "morning_id": 4,
                "value": true
            },
            {
                "morning_id": 5,
                "value": false
            },
            {
                "morning_id": 6,
                "value": false
            }
        ],
        "afternoon": [
            {
                "afternoon_id": 0,
                "value": true
            },
            {
                "afternoon_id": 1,
                "value": true
            },
            {
                "afternoon_id": 2,
                "value": true
            },
            {
                "afternoon_id": 3,
                "value": true
            },
            {
                "afternoon_id": 4,
                "value": true
            },
            {
                "afternoon_id": 5,
                "value": true
            },
            {
                "afternoon_id": 6,
                "value": true
            }
        ],
        "night": [
            {
                "night_id": 0,
                "value": false
            },
            {
                "night_id": 1,
                "value": false
            },
            {
                "night_id": 2,
                "value": false
            },
            {
                "night_id": 3,
                "value": false
            },
            {
                "night_id": 4,
                "value": true
            },
            {
                "night_id": 5,
                "value": true
            },
            {
                "night_id": 6,
                "value": false
            }
        ]
    },

    morning: [],
    afternoon: []
})
